/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react'
// import { render } from 'react-dom'
import { Form, Field } from 'react-final-form'

import { Link } from "gatsby"
import Layout from '../components/layout'

const Anmeldung = () => {
  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  const required = value => (value ? undefined : 'Required')
  const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)


  const [submitted, setSubmitted] = useState(0);

  const onSubmit = async values => {
    await sleep(300)
    // window.alert(JSON.stringify(values, 0, 2))
    // console.log(JSON.stringify(values,0,2));

    const recipeUrl = '/OjieylV2cml6gnS9W9Vy.php';
    const postBody = JSON.stringify(values,0,2);
    const requestMetadata = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: postBody
    };

    fetch(recipeUrl, requestMetadata)
        .then(res => res.text())
        .then(data => {
            // console.log(data);
            if(data == 200) {
                setSubmitted(200)
            } else {
                console.log(data);
                setSubmitted(500);
            }
        }).catch(
          error => {
            console.log(error);
            setSubmitted(500);
        });

  }

  return(
    <Layout title="Online Anmeldeformular Eintritt Bannau">
        <div style={{maxWidth: '800px', margin: 'auto'}}>
        <h1>Anmeldeformular Eintritt Bannau</h1>

        <div style={{display: submitted == 200 ? 'block' : 'none', margin: '1em auto 3em auto'}}>
          <li className="formular-download-link formular-download-link--disabled" style={{fontSize: '1.1em'}}>Anmeldung erfolgreich abgesendet! Wir melden uns demnächst bei Ihnen.</li>
        </div>

        <div style={{display: submitted == 500 ? 'block' : 'none', margin: '1em auto 3em auto'}}>
          <li className="formular-download-link formular-download-link--disabled" style={{fontSize: '1.1em'}}>Es ist ein Fehler aufgetreten. Bitte nutzen Sie unser <a href="/download/Anmeldung.pdf">PDF-Formular</a>.</li>
        </div>

        <div style={{display: submitted == 0 ? 'block' : 'none'}}>
        <Form
        onSubmit={onSubmit}
        initialValues={{ entwicklungsmodus: true }}
        render={({ handleSubmit, form, submitting, pristine, values, invalid }) => (
            <form onSubmit={handleSubmit} className="anmeldung-form" autoComplete="off">
            <Field name="name" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    {/* <label>Ihr Name* </label> */}
                    <input {...input} type="text" placeholder="Ihr Name *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="address" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Ihre Adresse *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="tel" validate={composeValidators(required, mustBeNumber)}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="tel" placeholder="Ihre Telefonnummer *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="birthday" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <label>Ihr Geburtsdatum * </label>
                    <input {...input} type="date" placeholder="Ihr Geburtsdatum *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <div className="form-row">
                <label>Ihr Zivilstand *</label>
                <Field name="zivilstand" component="select" validate={required} required>
                <option />
                <option value="verheiratet">Verheiratet</option>
                <option value="verwitwet">Verwitwet</option>
                <option value="ledig">Ledig</option>
                <option value="geschieden">Geschieden</option>
                </Field>
            </div>
            <div className="form-row">
                <label>Ihr Konfession *</label>
                <Field name="konfession" component="select" validate={required} required>
                <option />
                <option value="protestantisch">Protestantisch</option>
                <option value="katholisch">Katholisch</option>
                <option value="konfessionslos">Konfessionslos</option>
                <option value="andere">Andere</option>
                </Field>
            </div>
            <Field name="heimatort" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Ihr Heimatort *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="krankenkasse" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Ihre Krankenkasse, Adresse *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="versichertennummer" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Ihre Versichertennummer *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="ahv-nummer" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Ihre AHV-Nummer *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="pflege" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Ihre Pflegebedürftigkeit *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="bezugleistungen" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Bezüger v. Ergänzungsleistungen *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="email" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="email" placeholder="Ihre E-Mail Adresse *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>

            <h3 style={{margin: '1em 0 0.2em 0'}}>Angehörige / Kontaktperson</h3>
            <Field name="ang-name" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Name Kontaktperson *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="ang-adresse" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Adresse Kontaktperson *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="ang-tel" validate={composeValidators(required, mustBeNumber)}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="tel" placeholder="Telefonnummer Kontaktperson *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            <Field name="ang-verwandschaft" validate={required}>
                {({ input, meta }) => (
                    <div className="form-row">
                    <input {...input} type="text" placeholder="Verwandschaftsgrad Kontaktperson *" className={meta.error && meta.touched ? 'field-error' : ''}/>
                    </div>
                )}
            </Field>
            
            

            <p style={{margin: '1em auto 0.5em auto'}}>
                Mit dem Absenden des Anmeldeformulars akzeptiere ich die <Link to="/datenschutzerklaerung/">Datenschutzerklärung</Link>.
            </p>
            <button type="submit" className="button-submit" disabled={submitting || pristine || invalid}>
                Absenden
            </button>
            <div style={{marginTop: '2em'}}></div>

            {/* Output Values in Real Time
            <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </form>
        )}
        />
        </div>
        </div>
  </Layout>
  )
}

export default Anmeldung

// render(<App />, document.getElementById('root'))
